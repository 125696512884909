import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import ReactPlayer from 'react-player';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import fileDownload from 'js-file-download';
const Axios = require('axios');

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: sessionStorage.getItem('userEmail') || false,
            gameActive: false,
            seenVideo: false,
            tenantRules: {},
            currentPick: {},
            winner: "",
            racers: [],
            selectedItem: 0,
            videoState: 0,
            status: 0,
            timeToSendEmails: null,
            videoDuration: "0:00",
            width: 0,
            height: 0,
            highlightedPick: false,
            questionAnswered: false,
            formFilledOut: false,
            userAnswers: [],
            alreadyUpdate: "",
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            downloadingImage: false,
            downloadedFile: null,
        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
    }

    handleGameMethods(){
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!this.validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                this.logUserActivity(key)
                this.removeReactAlert();
                this.setState({
                    currentGameId:key,
                    highlightedPick: false
                })
            }
        });
        this.gameActiveRef = base.bindToState('currentGame/active', {
            context: this,
            state: 'gameActive'
        })
        this.racersRef = base.bindToState('currentGame/racers', {
            context: this,
            state: 'racers',
            asArray: true
        })
        this.currentPickRef = base.bindToState('userPicks/'+base64EncodedEmail, {
            context: this,
            state: 'currentPick',
            then(){
                if(this.props.variables && this.props.variables.collectDistance && !this.state.currentPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance){
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        locationChecking: false,
                        loading:false
                    })
                }
            }
        })
        this.winnerRef = base.listenTo('currentGame/winner', {
            context: this,
            state: 'winner',
            then(winner){
                this.setState({
                    winner: winner
                });
            }
        })
        this.prizesWonRef = base.listenTo('prizeWon/'+base64EncodedEmail, {
            context: this,
            then(winnerPrize){
                if(winnerPrize){
                    this.setState({
                        rewardName: winnerPrize.rewardName,
                        winningPrizeImage: winnerPrize.rewardImage,
                        winningCode: winnerPrize.code
                    })
                } else {
                    this.setState({
                        rewardName: null,
                        winningPrizeImage: null,
                        winningCode: null
                    })
                }
            }
        });

        this.statusRef = base.listenTo('currentGame/status', {
            context: this,
            state: 'status',
            then(status){
                this.setState({
                    status: status
                });
            }
        })
        this.currentVideoStateRef = base.listenTo('currentGame/videoState', {
            context: this,
            state: 'videoState',
            then(videoState){
                this.setState({
                    videoState: videoState
                }, () => {
                    let racerWinnerVideo;
                    for(const racerIndex in this.state.racers){
                        if(this.state.racers[racerIndex].winner){
                            racerWinnerVideo = this.state.racers[racerIndex].racerWinningVideoLink;
                        }
                    }
                    if(videoState === 2 && this.state.currentPick.pickid && racerWinnerVideo && !this.state.currentPick.seenVideo){
                        this.setState({
                            modal:true
                        });
                    } else if(videoState === 2) {
                        this.alertIfWonOrLost();
                    } else if(videoState === 1 && racerWinnerVideo) {
                        this.setState({
                            modal:true
                        });
                    } else if(videoState === 0){
                        this.setState({
                            modal:false,
                            startConfetti: false
                        });
                    }
                })
            }
        })
    }

    componentDidMount() {
      if(!navigator.cookieEnabled){
        alert("You do not have cookies enabled! Please change the settings of your browser");
        return;
      }
      if(window.location.pathname === "/seasonlong"){
          this.handleSeasonLongMethods();
      } else {
          this.handleGameMethods();
      }

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    handleSeasonLongMethods(){
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!this.validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.campaignPicksRef = base.bindToState('campaignPicks/'+base64EncodedEmail, {
            context: this,
            state: 'campaignPick',
            then(){
                if(this.props.variables && this.props.variables.collectDistance && !this.state.campaignPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance){
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        locationChecking: false,
                        loading:false
                    })
                }
            }
        })
        this.allRacersListRef = base.bindToState('racerList', {
            context: this,
            state: 'allRacersList',
            asArray: true
        })
    }

    validateEmail(email) {
        let re;
        re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    onEnded(){
        this.setState({
            modal: false
        }, () => {
            this.alertIfWonOrLost();
            const userEmail = this.state.userEmail;
            let base64EncodedEmail = btoa(userEmail);
            if(!this.validateEmail(userEmail)){
                base64EncodedEmail = userEmail;
            }
            if(base64EncodedEmail){
                base.post('userPicks/'+base64EncodedEmail+'/seenVideo', {
                    data: true,
                    then(err){
                        if(err){
                            console.log(err)
                        } else {
                            console.log("success")
                        }
                    }
                })
            }
        })
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element')
      const target = document.getElementById('react-confirm-alert')
      if(target){
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg')
      if(svg){
        svg.parentNode.removeChild(svg)
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentWillUnmount() {
        if(window.location.pathname === "/seasonlong") {
            base.removeBinding(this.campaignPicksRef);
            base.removeBinding(this.allRacersListRef);
        } else {
            base.removeBinding(this.currentPickRef);
            base.removeBinding(this.racersRef);
            base.removeBinding(this.gameActiveRef);
            base.removeBinding(this.currentGameKeyRef);
            base.removeBinding(this.winnerRef);
            base.removeBinding(this.statusRef);
            base.removeBinding(this.currentVideoStateRef);
            base.removeBinding(this.prizesWonRef);
        }
        base.removeBinding(this.tenantRulesRef);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.videoState === 2 && this.state.videoState === 0){
            this.removeReactAlert();
            this.setState({
                startConfetti:false,
                winnerModal: false
            })
        }
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false,
            downloadedFile: null
        });
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    download(url) {
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const variables = this.props.variables;
        const downloadImageRequest = {prizeImage: url, code: this.state.winningCode}
        const vm = this;
        // fetch(`http://localhost:5001/photoscrambledev/us-central1/setUpRewardForDownload`, {
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/setUpRewardForDownload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(downloadImageRequest),
        }).then(res => {
            if(res){
                Promise.resolve(res.json()).then(function (value) {
                    console.log(value)
                    if(!value || value.result === "no_prize_sent" || value.error){
                        confirmAlert({
                            variables: variables,
                            title: "Error",
                            message: "Something went wrong",
                            buttons: [
                                {
                                    label: 'OK'
                                }
                            ]
                        })
                        vm.setState({
                            downloadingImage:false
                        })
                    } else {
                        Axios.get(value.newImage, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, "reward.jpg")
                            vm.setState({
                                downloadingImage:false,
                                downloadedFile: res.data
                            })
                        });
                    }
                }).catch(e =>{
                    confirmAlert({
                        variables: variables,
                        title: "Error",
                        message: "Something went wrong",
                        buttons: [
                            {
                                label: 'OK'
                            }
                        ]
                    })
                    console.log(e)
                    vm.setState({
                        downloadingImage:false
                    })
                })
            } else {
                confirmAlert({
                    variables: variables,
                    title: "Error",
                    message: "Something went wrong",
                    buttons: [
                        {
                            label: 'OK'
                        }
                    ]
                })
                vm.setState({
                    downloadingImage:false
                })
            }
        })
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            sessionStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            sessionStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = sessionStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    logout(){
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        this.setState({
            userEmail:false
        })
    }

    logUserActivity(gameId){
      const vm = this;
      if(gameId){
        const userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameId}` , {
          data:gameId,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameId,
              });
              console.log("user game logged!")
            }
          }
        })
      }
    }

    onClickPick(pickClicked, position){
      if(this.state.currentPick.pickid){
        // confirmAlert({
        //     title: 'Sorry!',
        //     variables: this.props.variables,
        //     message: "We weren't kidding about you being locked in....You're stuck with the your choice.",
        //     buttons: [
        //       {
        //         label: 'OK',
        //       }
        //     ]
        //   })
          return
      }
      if(this.state.status === 2){
        confirmAlert({
          title: "Oh no!",
          variables: this.props.variables,
          message: "The race already happened but come back next time to try again!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        });
        return;
      } else if(this.state.status === 1){
          confirmAlert({
              title: "Picks Frozen!",
              variables: this.props.variables,
              message: "The race has started and no more picks are allowed!",
              buttons: [
                  {
                      label: 'OK',
                  }
              ]
          });
          return;
      }
      this.setState({
        highlightedPick: pickClicked.id
      })
    }

    onClickCampaignPick(pickClicked){
        if(this.state.campaignPick.pickid){
            return
        }
        this.setState({
            highlightedPick: pickClicked.id || pickClicked.key
        })
    }

    onSubmitCampaignAnswer(){
        const tenantVariables = this.props.variables || {};
        const highlightedPick = this.state.highlightedPick;
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!this.validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        var postToUserPicks = {};
        var userSelectionObject = {};
        const vm = this;
        userSelectionObject.pickid = highlightedPick;
        userSelectionObject.uid = base64EncodedEmail;
        userSelectionObject.timeStamp = Date.now();
        postToUserPicks["/campaignPicks/" + base64EncodedEmail] = userSelectionObject;
        const seasonLongHeaderPick = tenantVariables.selectCharacterCampaignHeader || "Select This Character?";
        const seasonLongBodyPick = tenantVariables.selectCharacterCampaignBody || "Once you choose you're locked in & can't switch.";
        confirmAlert({
            title: seasonLongHeaderPick,
            variables: vm.props.variables,
            message: seasonLongBodyPick,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                            if(!error){
                                vm.setState({
                                    highlightedPick: false
                                }, ()=>{
                                    const afterPickCampaignHeader = tenantVariables.afterPickCampaignHeader || "Good Luck!";
                                    const afterPickCampaignBody = tenantVariables.afterPickCampaignBody || "We'll see you at the finish line.";
                                    confirmAlert({
                                        title: afterPickCampaignHeader,
                                        variables: vm.props.variables,
                                        message: afterPickCampaignBody,
                                        buttons: [
                                            {
                                                label: "Ok"
                                            }
                                        ]
                                    })
                                })
                            } else {
                                console.log(error);
                                confirmAlert({
                                    title: 'There was an error',
                                    variables: vm.props.variables,
                                    message: error.message,
                                    buttons: [
                                        {
                                            label: 'OK',
                                        }
                                    ]
                                })
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("dismiss")
                }
            ],
        })
    }

    onSubmitAnswer(){
        const tenantVariables = this.props.variables || {};
      const highlightedPick = this.state.highlightedPick;
      if(!highlightedPick){
        return;
      }
        if(this.state.videoState === 2){
            confirmAlert({
                title: "Oh no!",
                variables: tenantVariables,
                message: "The race already happened but come back next time to try again!",
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        } else if(this.state.videoState === 1){
            confirmAlert({
                title: "Picks Frozen!",
                variables: tenantVariables,
                message: "The race has started and no more picks are allowed!",
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        }
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!this.validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
      var postToUserPicks = {};
      var userSelectionObject = {};
      const vm = this;
      userSelectionObject.pickid = highlightedPick;
      userSelectionObject.uid = base64EncodedEmail;
      userSelectionObject.timeStamp = Date.now();
      postToUserPicks["/userPicks/" + base64EncodedEmail] = userSelectionObject;
        const gameHeaderPick = tenantVariables.selectCharacterGameHeader || "Select This Character?";
        const gameBodyPick = tenantVariables.selectCharacterGameBody || "Once you choose you're locked in & can't switch.";
      confirmAlert({
          title: gameHeaderPick,
          variables: vm.props.variables,
          message: gameBodyPick,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                  if(!error){
                    vm.setState({
                      highlightedPick: false
                    }, ()=>{
                        const afterPickHeader = tenantVariables.afterPickHeader || "Good Luck!";
                        const afterPickBody = tenantVariables.afterPickBody || "We'll see you at the finish line.";
                        confirmAlert({
                            title: afterPickHeader,
                            variables: vm.props.variables,
                            message: afterPickBody,
                            buttons: [
                                {
                                    label: "Ok"
                                }
                            ]
                        })
                    })
                  } else {
                    console.log(error);
                    confirmAlert({
                        title: 'There was an error',
                        variables: vm.props.variables,
                        message: error.message,
                        buttons: [
                          {
                            label: 'OK',
                          }
                        ]
                      })
                  }
                })
              }
            },
            {
              label: 'No',
              onClick: () => console.log("dismiss")
            }
          ],
      })
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const textAbovePlayingText = tenantVariables.textAbovePlayingText || "Next game starts during the";
      const textColor = tenantVariables.textColor || "#fff";
      return(
        <div className="flex-content-container-home">
          <div className="flex-header-home">
            <div className="header-left">
              <img className="topLeftLogo" alt=""/>
            </div>
              {!this.props.passedEmail &&
              <div className="header-right">
                  <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: textColor, borderColor: textColor}}>LOG OUT</button>
              </div>
              }
          </div>
          <div className="intro-container-home" >
            <div className="hero-text-container">
              <img src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{display: tenantVariables.playingText ? '' : "none", color:textColor}}>{textAbovePlayingText} <br/><span className="emphasizedText" style={{backgroundColor:tenantVariables.primaryColor, color:tenantVariables.secondaryColor}}>{tenantVariables.playingText}</span></p>
          </div>
        </div>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const racers = this.state.racers;
      let videoURL = "";
      for(const racerIndex in racers){
          if(racers[racerIndex].winner){
              if(racers[racerIndex].racerWinningVideoLink){
                  videoURL = racers[racerIndex].racerWinningVideoLink;
              } else if(racers[racerIndex].racerWinningVideoFile){
                  videoURL = racers[racerIndex].racerWinningVideoFile;
              }
          }
      }
      const whoWillWinText = tenantVariables.aboveSelectionsText || "Who Will Win?";
        // const textColor = tenantVariables.textColor || "#fff";
      return(
        <div className="flex-content-container-home">
          <div className="intro-container-home">
              {/*<div className="header-right">*/}
              {/*    <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: textColor, borderColor: textColor}}>LOG OUT</button>*/}
              {/*</div>*/}
            <div className="grid-wrapper">
              <div className="user" style={{marginTop: 10}}><img src={tenantVariables.topLeftImage} alt="" style={{marginBottom:"0"}}/></div>
              <div className="top-text">
                  <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
              </div>
              <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
              onChange={(e) => {
        					this.setState({
        						selectedItem: e,
        					});
        				}}>
                  {
                    racers.map(function(item,i){
                      if(item.id === this.state.currentPick.pickid || item.id === this.state.highlightedPick){
                        return <div
                                  key={i}
                                  id={item.id}
                                  className="grid-item"
                                  style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                  <img src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                </div>
                      } else {
                        return <div
                                  key={i}
                                  id={item.id}
                                  className="grid-item"
                                  onClick={()=>this.onClickPick(item, i)}
                                  style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                  <img src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                </div>
                      }
                    }, this)
                  }
              </Carousel>
              <style dangerouslySetInnerHTML={{
                  __html: [
                     '.carousel .control-next.control-arrow:before {',
                     'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .control-prev.control-arrow:before {',
                     'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .thumb.selected, .carousel .thumb:hover {',
                     '  border: 3px solid ' + tenantVariables.highlightPickColor,
                     '}'
                    ].join('\n')
                  }}>
                </style>
              <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitAnswer()}>SUBMIT PICK</button>
            </div>
          </div>
            <Modal isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" contentClassName="transparentBackground">
                {process.env.REACT_APP_FIREBASE_PROJECT_ID === "racechicagobears" ?
                    <ReactPlayer playsinline={false} volume={null} url={videoURL} muted={false} playing={false} onEnded={this.onEnded} controls={true} width="100%" height="auto"/>
                :
                    <ReactPlayer playsinline={true} volume={0} url={videoURL} muted={true} playing={true} onEnded={this.onEnded} controls={false} width="100%" height="auto"/>
                }
            </Modal>
            <Modal isOpen={this.state.winnerModal} id="myOtherModal">
                <center>
                    <img style={{padding:10, margin:"auto"}} width={300} height={300} src={this.state.winningPrizeImage} alt=""/>
                    <p style={{display: this.state.winningCode ? "" : "none"}}>{this.state.winningCode}</p>
                    <div style={{marginTop:5, marginBottom: 5}}>
                        {!this.state.downloadingImage ?
                            <button type="button" onClick={()=>this.download(this.state.winningPrizeImage, "yourprize.jpg")} className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Download</button>
                            :
                            <button type="button" className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>
                                <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading
                            </button>
                        }
                    </div>
                    {/*<div style={{marginTop:5, marginBottom: 5}}>*/}
                    {/*    <button type="button" className="btn btn-next" onClick={()=> this.winnerToggle()} style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Go Back</button>*/}
                    {/*</div>*/}
                </center>
            </Modal>
        </div>
        )
    }

    renderSeasonLongScreen(){
        const tenantVariables = this.props.variables || {};
        const racers = this.state.allRacersList || [];
        const whoWillWinText = tenantVariables.campaignLongInfoText || "Pick Your Season Long Pick";
        return(
            <div className="flex-content-container-home">
                <div className="intro-container-home">
                    <div className="grid-wrapper">
                        <div className="user" style={{marginTop: 10}}><img src={tenantVariables.seasonLongTopLogo || tenantVariables.topLeftImage} alt="" style={{marginBottom:"0"}}/></div>
                        <div className="top-text">
                            <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                        </div>
                        <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                                  onChange={(e) => {
                                      this.setState({
                                          selectedItem: e,
                                      });
                                  }}>
                            {
                                racers.map(function(item,i){
                                    const itemId = item.id || item.key;
                                    if(itemId === this.state.campaignPick.pickid || itemId === this.state.highlightedPick){
                                        return <div
                                            key={i}
                                            id={itemId}
                                            className="grid-item"
                                            style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                            <img src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                        </div>
                                    } else {
                                        return <div
                                            key={i}
                                            id={itemId}
                                            className="grid-item"
                                            onClick={()=>this.onClickCampaignPick(item)}
                                            style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                            <img src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                        </div>
                                    }
                                }, this)
                            }
                        </Carousel>
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.carousel .control-next.control-arrow:before {',
                                'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                                ';border-top: 16px solid transparent',
                                ';border-bottom: 16px solid transparent',
                                '}',
                                '.carousel .control-prev.control-arrow:before {',
                                'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                                ';border-top: 16px solid transparent',
                                ';border-bottom: 16px solid transparent',
                                '}',
                                '.carousel .thumb.selected, .carousel .thumb:hover {',
                                '  border: 3px solid ' + tenantVariables.highlightPickColor,
                                '}'
                            ].join('\n')
                        }}>
                        </style>
                        <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitCampaignAnswer()}>SUBMIT PICK</button>
                    </div>
                </div>
            </div>
        )
    }

    throwWinningAlert(){
      const tenantVariables = this.props.variables || {};
      if(tenantVariables.doNotCollectEmail){
          this.setState({
              winnerModal: true
          })
      } else {
          confirmAlert({
              title: tenantVariables.winningHeader,
              variables: this.props.variables,
              message: tenantVariables.winningMessage,
              buttons: [
                  // {
                  //   label: 'OK',
                  // }
              ]
          })
      }
    }

    throwLosingAlert(){
      var tenantVariables = this.props.variables || {};
      // " Looks like you got the answer wrong. Come back next time to try again!"
      confirmAlert({
        title: tenantVariables.losingHeader,
        variables: this.props.variables,
        message: tenantVariables.losingMessage,
        buttons: [
          // {
          //   label: 'OK',
          // }
        ]
      })
    }

    throwNotInTimeAlert(){
      confirmAlert({
        title: "Oh no!",
        variables: this.props.variables,
        message: "The race already happened but come back next time to try again!",
        buttons: [
          // {
          //   label: 'OK',
          // }
        ],
      })
    }

    alertIfWonOrLost(){
      if(typeof this.state.currentPick.pickid === "undefined"){
          this.throwNotInTimeAlert();
      } else if(this.state.winner === this.state.currentPick.pickid){
        this.throwWinningAlert()
          this.setState({
              startConfetti: true
          })
      } else {
        this.throwLosingAlert()
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const location = window.location.pathname;
      if(!this.state.userEmail){
          let nextUrl = 'login'
          if(location === "/seasonlong"){
              nextUrl = "/login?nexturl=seasonlong"
          }
            return <Redirect to={nextUrl} />
      }
      if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} loading={this.state.loading} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
      let renderMainScreen = "";
      if(location === "/seasonlong"){
          renderMainScreen = this.renderSeasonLongScreen()
      } else if(this.state.gameActive && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      } else {
        renderMainScreen = this.renderHoldingScreen()
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")"}}>
              {this.state.startConfetti &&
                <Confetti width={this.state.width} height={this.state.height} style={{zIndex: 1 }}/>
              }
            {renderMainScreen}
          </div>
      )
    }
}

export default Main_App;
